import React, { useEffect, useState } from "react";
import Header from "./component/header";
import Footer from "./component/footer";
import DataTable from "../../common-components/dataTable";
import { connect } from "react-redux";
import {
  getStatusKeyValuPair,
  getTestPurchased,
  getTestPurchasedExport,
  getTestPurchasedKeyValuePair,
  getTestPurchasedOfIndividual,
} from "../../actions/adminTestPurchaseActionCreator";
import {
  IFilterData,
  ITestPurchasedDisplay,
} from "../../models/admin/testPurchased";
import { useNavigate } from "react-router-dom";
import { ROUTER_URL } from "../../constants/routerUrl";
import FloatingLabelDropDown from "../../common-components/floatingLabelDropdown";
import "react-datepicker/dist/react-datepicker.css";
import DatePicker from "react-datepicker";
import moment from "moment";
import {
  getStatuses,
  getTestNames,
} from "../../actions/common/commonActionCreator";
import { pageSize } from "../../constants/constants";
import FloatingLabelTextInput from "../../common-components/floatingLabelTextInput";
import { IFilterKeyword } from "../../models/admin/userList";
import { Calendar } from "primereact/calendar";
import "primereact/resources/themes/lara-light-cyan/theme.css";

const TestPurchased = (props: ITestPurchasedDisplay) => {
  const {
    testPurchasedData,
    testPurchasedFilter,
    testPurchasedStatusFilter,
    getTestPurchased,
    getTestPurchasedExport,
    getTestPurchasedOfIndividual,
    statuses,
    testNames,
    getStatuses,
    getTestNames,
  } = props;
  const [selectedPage, setSelectedPage] = useState(1);
  const navigate = useNavigate();
  const [data, setData] = useState<IFilterData>({
    // registerdate: moment(new Date()).format("MM-DD-yyyy"),
    keyword: "",
  });
  const [dates, setDates] = useState<any>([
    new Date(moment(new Date()).format("MM-DD-YYYY")),
    new Date(moment(new Date()).format("MM-DD-YYYY")),
  ]);
  const [keyWord, setKeyWord] = useState(false);
  const [filterDate, setFilterDate] = useState(false);
  const [needDate, setNeedDate] = useState(false);

  //function to execute onChange event
  const onChange = (
    event:
      | any
      | React.ChangeEvent<HTMLInputElement>
      | React.ChangeEvent<HTMLSelectElement>
      | React.ChangeEvent<HTMLSelectElement>
      | React.ChangeEvent<HTMLSelectElement>
      | React.ChangeEvent<HTMLTextAreaElement>
  ) => {
    if (event.keyCode == 13) {
      setKeyWord(true);
    } else {
      setKeyWord(false);
      const _data = {
        ...data,
        [event.target.name]: event.target.value,
      };
      setData(_data);
      setSelectedPage(1);
    }
  };

  const onChangeCalendar = (e: any) => {
    setDates(e.value);
    setFilterDate(true);
    setNeedDate(true);
  };

  // fields which are to be displayed on the table.
  const columns = [
    {
      label: "Deal Id",
      prop: "dealId",
    },
    {
      label: "Deal Name",
      prop: "dealName",
    },
    {
      label: "Name",
      prop: "purchasedFor",
    },
  ];

  // executes when edit button is clicked
  const downloadItem = (id: number) => {
    navigate(ROUTER_URL.ADMIN_TEST_RESULT_DOWNLOAD);
  };

  // executes when a page number is set or clicked
  const onPageChange = (pageNo: number) => {
    setSelectedPage(pageNo);
  };

  // executes when a page number is set or clicked
  const exportTestPurchase = () => {
    if (needDate === true || data.keyword) {
      getTestPurchasedExport(
        selectedPage,
        "",
        data.keyword,
        dates[0]
          ? moment(dates[0]).format("MM-DD-YYYY")
          : moment().format("MM-DD-YYYY"),
        dates[1]
          ? moment(dates[1]).format("MM-DD-YYYY")
          : moment().format("MM-DD-YYYY")
      );
    } else {
      getTestPurchasedExport("", "", data.keyword, "", "");
    }
  };

  useEffect(() => {
    const fromDate = dates[0]
      ? moment(dates[0]).format("MM-DD-YYYY")
      : moment().format("MM-DD-YYYY");
    const toDate = dates[1]
      ? moment(dates[1]).format("MM-DD-YYYY")
      : moment().format("MM-DD-YYYY");
    if (keyWord === true) {
      getTestPurchased(
        selectedPage,
        pageSize,
        data.keyword,
        needDate === true ? fromDate : "",
        needDate === true ? toDate : ""
      );
      setFilterDate(false);
    } else {
      getTestPurchased(
        selectedPage,
        pageSize,
        data.keyword,
        needDate === true ? fromDate : "",
        needDate === true ? toDate : ""
      );
      setFilterDate(false);
    }
    // getTestPurchased(needDate?1:selectedPage, pageSize, data.keyword, needDate===true?fromDate:"", needDate===true?toDate:"");
  }, [selectedPage, data.keyword, filterDate]);

  useEffect(() => {
    getStatuses();
    getTestNames();
  }, []);

  return (
    <>
      <Header />

      <main>
        <div className="container">
          <div className="admin-title">
            <h4>
              {testPurchasedData && testPurchasedData?.totalRecords
                ? testPurchasedData.totalRecords === 1
                  ? testPurchasedData.totalRecords + " " + "Test Purchased"
                  : testPurchasedData.totalRecords > 1
                  ? testPurchasedData.totalRecords + " " + "Test's Purchased"
                  : "Test Purchased"
                : "Test Purchased"}
            </h4>
          </div>
          <div className="filter-wrap d-flex align-items-center justify-content-between gap-10 mb-3">
            <div className="search">
              <input
                type="text"
                placeholder="Search"
                value={data.keyword}
                name="keyword"
                onChange={onChange}
                onKeyUp={onChange}
              />{" "}
              <button>
                <img src="assets/images/icons/search.svg" alt="" />
              </button>
            </div>
            <div className="filter-card btn-wrap d-flex align-items-center justify-content-end gap-10">
              <div className="form-group pad-right m-0">
                <Calendar
                  value={dates}
                  style={{ width: "300px" }}
                  // onChange={(e) => {
                  //   setDates(e.value);
                  //   setFilterDate(true);
                  // }}
                  onChange={onChangeCalendar}
                  selectionMode="range"
                  readOnlyInput
                  dateFormat="mm-dd-yy"
                />
              </div>
              {testPurchasedData && testPurchasedData.data?.length ? (
                testPurchasedData.data.length > 0 ? (
                  <button className="btn-primary" onClick={exportTestPurchase}>
                    Export
                  </button>
                ) : (
                  <button
                    className="btn-primary"
                    onClick={exportTestPurchase}
                    disabled
                  >
                    Export
                  </button>
                )
              ) : (
                <button
                  className="btn-primary"
                  onClick={exportTestPurchase}
                  disabled
                >
                  Export
                </button>
              )}
            </div>
          </div>
          <div className="data-table mb-3">
            <DataTable
              columns={columns}
              noDataFound={"No data found"}
              tabelData={testPurchasedData}
              onPageChange={onPageChange}
              // showDrilldown={true}
              bgColor={"status"}
              selectedPage={selectedPage}
              paginateItem={true}
              isTestPurchasedDetails={true}
              isTestPurchasedRegisteredDate={true}
              // isMultiProduct="isMultiProduct"
              // isMultiProductDownload="isMultiProductDownload"
            />
          </div>
        </div>
      </main>

      <Footer />
    </>
  );
};

const mapDispatchToProps = {
  getTestPurchased: (
    pageNumber: number,
    pageSize: number,
    keyword: string,
    fromDate?: string,
    toDate?: string
  ) => getTestPurchased(pageNumber, pageSize, keyword, fromDate, toDate),
  getTestPurchasedExport: (
    pageNumber: number,
    pageSize: number,
    keyword?: string,
    fromDate?: string,
    toDate?: string
  ) => getTestPurchasedExport(pageNumber, pageSize, keyword, fromDate, toDate),
  getTestPurchasedOfIndividual: (id: number) =>
    getTestPurchasedOfIndividual(id),
  getTestNames: () => getTestNames(),
  getStatuses: () => getStatuses(),
};

function mapStateToProps(state: any) {
  return {
    testPurchasedData: state.adminTestPurchase?.testPurchasedData,
    userListExportData: state.adminTestPurchase?.userListExportData,
    statuses: state.commonDropdowns?.statuses,
    testNames: state.commonDropdowns?.testNames,
  };
}

export default connect(mapStateToProps, mapDispatchToProps)(TestPurchased);
