import React, { useEffect, useState } from "react";
import Header from "./component/header";
import Footer from "./component/footer";
import DataTable from "../../common-components/dataTable";
import { connect } from "react-redux";
import { useNavigate } from "react-router-dom";
import { ROUTER_URL } from "../../constants/routerUrl";
import {
  IActivateOrDeactivate,
  IFilterKeyword,
  IResetPassword,
  IUserListDisplay,
} from "../../models/admin/userList";
import {
  activateOrDeactivate,
  getUserList,
  getUserListExport,
} from "../../actions/adminUserListActionCreator";
import { IFocus } from "../../models/profile";
import ReactPaginate from "react-paginate";
import { defaultpageCount } from "../../constants/constants";
import AdminResetPassword from "./component/adminResetPassword";
import { resetPassword } from "../../actions/loginActionCreator";
import { alertConstants } from "../../helper/sweet-alert";

const UserList = (props: IUserListDisplay) => {
  const {
    userListData,
    userListExportData,
    getUserList,
    getUserListExport,
    activateOrDeactivate,
    resetPassword,
  } = props;
  const [selectedPage, setSelectedPage] = useState(1);
  const [isFullProfileVisible, setIsFullProfileVisible] = useState(false);
  const [userEmail, setUserEmail] = useState("");
  const [keyWord, setKeyWord] = useState(false);
  const [active, setActive] = useState(false);
  const [emptyKeyword, setEmptyKeyword] = useState(false);
  const [filterData, setFilterData] = useState<string>("");

  const navigate = useNavigate();

  let pageSize = 5;
  let id = 2;

  //function to execute onChange event
  const onChange = (
    event:
      | any
      | React.ChangeEvent<HTMLInputElement>
      | React.ChangeEvent<HTMLSelectElement>
      | React.ChangeEvent<HTMLSelectElement>
      | React.ChangeEvent<HTMLSelectElement>
      | React.ChangeEvent<HTMLTextAreaElement>
  ) => {
    if (event.keyCode == 13) {
      setKeyWord(true);
    } else {
      // setKeyWord(false);
      // const _filterData = {
      //   ...filterData,
      //   [event.target.name]: event.target.value,
      // };
      // if (event.target.name == "keyword") {
      //   filterData.keyword == event.target.value;
      // }
      setFilterData(event.target.value);
      setSelectedPage(1);
    }
    if (filterData === "") {
      setEmptyKeyword(false);
    } else {
      setEmptyKeyword(true);
    }
  };

  // fields which are to be displayed on the table.
  const columns = [
    {
      label: "First Name",
      prop: "firstName",
    },
    {
      label: "Last Name",
      prop: "lastName",
    },
    {
      label: "Email",
      prop: "email",
    },
    {
      label: "Mobile Number",
      prop: "mobileNo",
    },
  ];

  // executes when activate or deactivate button is clicked
  const controlItem = (id: number, isActive: boolean) => {
    activateOrDeactivate(id, isActive, callback);
    setActive(true);
    // navigate(ROUTER_URL);
  };

  // executes when Reset button is clicked
  const resetPasswordItem = async (email: any) => {
    setUserEmail(email);
    localStorage.setItem("userMail", email);
    resetPassword(email, inReturn);
    setIsFullProfileVisible(true);
  };

  const inReturn = (response: any) => {
    if (alertConstants.success) {
    }
  };

  //execute when export button is clicked
  const exportFile = () => {
    getUserListExport(filterData);
  };

  // executes when a page number is set or clicked
  const onPageChange = (pageNo: number) => {
    setSelectedPage(pageNo);
  };

  const callback = () => {};

  let data = {
    id: 1,
    email: "string",
    active: true,
  };

  let details = {
    id: 9,
    email: "string",
    password: "jjjx",
  };

  useEffect(() => {
    getUserList(selectedPage, pageSize, filterData);
  }, [selectedPage, pageSize, filterData, keyWord]);

  return (
    <>
      <Header />
      <main>
        <div className="container">
          <div className="admin-title">
            <h4>{userListData?.totalRecords} Users</h4>
          </div>
          <div className="d-flex align-items-center justify-content-between mb-3">
            <div className="search">
              <input
                type="text"
                placeholder="Search"
                value={filterData}
                name="keyword"
                onChange={onChange}
                onKeyUp={onChange}
              />{" "}
              <button>
                <img src="assets/images/icons/search.svg" alt="" />
              </button>
            </div>
            {userListData ? (
              userListData.totalRecords > 0 ? (
                <div className="btn-wrap d-flex align-items-center justify-content-end gap-10">
                  <button className="btn-primary" onClick={exportFile}>
                    Export
                  </button>
                </div>
              ) : (
                <div className="btn-wrap d-flex align-items-center justify-content-end gap-10">
                  <button className="btn-primary" onClick={exportFile} disabled>
                    Export
                  </button>
                </div>
              )
            ) : (
              <div className="btn-wrap d-flex align-items-center justify-content-end gap-10">
                <button className="btn-primary" onClick={exportFile} disabled>
                  Export
                </button>
              </div>
            )}
          </div>
          <div className="data-table mb-3">
            <DataTable
              columns={columns}
              noDataFound={"No data found"}
              tabelData={userListData}
              onPageChange={onPageChange}
              showDrilldown={true}
              bgColor={"status"}
              selectedPage={selectedPage}
              // resetItem={resetItem}
              // controlItem={controlItem}
              resetPasswordItem={resetPasswordItem}
              paginateItem={true}

              // isActive={data.active}
            />
          </div>
        </div>
      </main>
      <AdminResetPassword
        isProfileVisible={isFullProfileVisible}
        email={setUserEmail}
        onClose={() => setIsFullProfileVisible(false)}
      />
      <Footer />
    </>
  );
};

const mapDispatchToProps = {
  getUserList: (pageNumber: number, pageSize: number, keyword: string) =>
    getUserList(pageNumber, pageSize, keyword),
  getUserListExport: (keyword: string) => getUserListExport(keyword),
  activateOrDeactivate: (
    userId: number,
    isActive: boolean,
    callback: Function
  ) => activateOrDeactivate(userId, isActive, callback),
  resetPassword: (email: string, callback: Function) =>
    resetPassword(email, callback),
  // resetPassword: (details: IResetPassword, callback: Function) =>
  //   resetPassword(details, callback),
};

function mapStateToProps(state: any) {
  return {
    userListData: state.adminUserList?.userListData,
    userListExportData: state.adminUserList?.userListExportData,
  };
}

export default connect(mapStateToProps, mapDispatchToProps)(UserList);
